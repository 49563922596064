import { Helmet } from "react-helmet";
import {
  ChakraProvider,
  Progress,
  Box,
  Button,
  Image,
  Flex,
  Container,
  Heading,
  Text,
  Tooltip,
} from "@chakra-ui/react";

import DirtyPix_ABI from "../contracts/DirtyPix.json";
import contractInfo from "../contracts/contract-info.json";
import { ethers } from "ethers";

import { useWeb3React } from "@web3-react/core";
import { WalletConnector } from "./WalletConnector";

import { extendTheme } from "@chakra-ui/react";
import { PolygonIcon } from "../libs/icons";
import { useCallback, useEffect, useState } from "react";
import MerkleTree from "merkletreejs";

const theme = extendTheme({
  components: {
    Heading: {
      variants: {
        closed: {
          opacity: 0.4,
        },
      },
    },
    Text: {
      variants: {
        important: {
          fontWeight: "bold",
        },
        openInfo: {
          color: "grey",
          fontSize: "12px",
          lineHeight: 1,
        },
      },
    },
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: "#D4903B",
        },
        track: {
          bg: "#555555",
        },
      },
    },
  },
  styles: {
    global: {
      "html, body": {
        background: "#1f1f1f",
        color: "#D4903B",
      },
      "div.main-box": {
        background: "#000000",
      },
      "div.widget": {
        background: "#1f1f1f",
      },
      "button.chakra-button": {
        color: "#000000",
        background: "#AC742A",
        vAlign: "center",
      },
      "button.chakra-button:hover": {
        background: "#FFDAB9",
      },
      "svg#polygon-matic-icon": {
        width: "14px",
        height: "14px",
      },
    },
  },
});

const WHITELIST_URL = "https://d4c84nvuifvs.cloudfront.net/whitelist.txt";
const WHITELIST_MINT_PRICE = 0;
const PUBLIC_MINT_PRICE = 3.9;
const MAX_SUPPLY = 42;

const fetchWhitelist = async (url: string) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Error fetching whitelist: ${response.statusText}`);
  }
  const text = await response.text();
  const addresses = text
    .split("\n")
    .map((line) => line.trim().toLowerCase())
    .filter((line) => line.length > 0);
  return addresses;
};

const getProof = (whitelist: string[], account: string) => {
  const accountIndex = whitelist.indexOf(account.toLowerCase());
  if (accountIndex === -1) {
    return null;
  }

  const leaves = whitelist.map((address) => ethers.keccak256(address));
  const merkleTree = new MerkleTree(leaves, ethers.keccak256, { sortPairs: true });

  const proof = merkleTree.getHexProof(leaves[accountIndex]);

  return proof;
};

export default function App() {
  const { library, account } = useWeb3React();
  const [contract, setContract] = useState<ethers.Contract | null>(null);
  const [whitelist, setWhitelist] = useState<string[]>([]);
  const [proof, setProof] = useState<string[] | null>(null);
  const [accountWhiteListMinted, setAccountWhiteListMinted] = useState(false);
  const [minted, setMinted] = useState(0);
  const [whiteListMintOpen, setWhiteListMintOpen] = useState(false);
  const [publicMintOpen, setPublicMintOpen] = useState(false);
  const [isPublicMintLoading, setIsPublicMintLoading] = useState(false);
  const [isWhiteListMintLoading, setIsWhiteListMintLoading] = useState(false);
  const accountWhiteListed = proof !== null;

  const mintedOut = minted === MAX_SUPPLY;
  let publicMintClosedReason: string | null = null;
  let whiteListMintClosedReason: string | null = null;

  if (mintedOut) {
    publicMintClosedReason = "Sold out";
    whiteListMintClosedReason = "Sold out";
  } else {
    if (!publicMintOpen) {
      publicMintClosedReason = "Public mint is closed";
    }
    if (!whiteListMintOpen) {
      whiteListMintClosedReason = "Whitelist mint is closed";
    } else if (!accountWhiteListed) {
      whiteListMintClosedReason = "You are not whitelisted";
    } else if (accountWhiteListMinted) {
      whiteListMintClosedReason = "You already minted";
    }
  }

  const publicMintEnabled = publicMintClosedReason === null;
  const whiteListMintEnabled = whiteListMintClosedReason === null;

  const handleTransfer = useCallback(
    (from: string, to: string, tokenId: ethers.BigNumberish): void => {
      if (from === ethers.ZeroAddress) {
        if (contract) {
          setMinted(minted + 1);
          contract.whiteListMinted(account).then((minted: boolean) => {
            setAccountWhiteListMinted(minted);
          });
        }
      }
    },
    [contract, account, minted]
  );

  useEffect(() => {
    fetchWhitelist(WHITELIST_URL).then(setWhitelist);
  }, []);

  useEffect(() => {
    if (library && account) {
      const proof = getProof(whitelist, account);
      setProof(proof);
      const signer = library.getSigner(account);
      const contract = new ethers.Contract(contractInfo.address, DirtyPix_ABI.abi, signer);
      setContract(contract);
    }
  }, [library, account, whitelist]);

  useEffect(() => {
    if (contract) {
      contract.totalSupply().then((supply: bigint) => {
        setMinted(Number(supply));
      });
      contract.whiteListMinted(account).then((minted: boolean) => {
        setAccountWhiteListMinted(minted);
      });
      contract.isWhiteListMintOpen().then((open: boolean) => {
        setWhiteListMintOpen(open);
      });
      contract.isPublicMintOpen().then((open: boolean) => {
        setPublicMintOpen(open);
      });
      contract.on("Transfer", handleTransfer);
      return () => {
        contract.off("Transfer", handleTransfer);
      };
    }
  }, [contract, account, handleTransfer]);

  const whiteListMint = async () => {
    if (contract) {
      setIsWhiteListMintLoading(true);
      try {
        await contract.whiteListMint(proof);
      } finally {
        setIsWhiteListMintLoading(false);
      }
    } else {
      alert("Contract not loaded");
    }
  };

  const publicMint = async () => {
    if (contract) {
      setIsPublicMintLoading(true);
      const price = ethers.parseEther("3.9");
      try {
        await contract.publicMint(1, { value: price });
      } finally {
        setIsPublicMintLoading(false);
      }
    } else {
      alert("Contract not loaded");
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Dirty Pix Enjoyers Club</title>
      </Helmet>

      <ChakraProvider theme={theme}>
        <Flex align="center" justify="center" minH="100vh">
          <Box className="main-box" p={6} rounded="lg" w="xl">
            <WalletConnector />
            <Container centerContent>
              <Image src="/logo.png" alt="Logo" w="50%" />
              <Heading textAlign="center" as="h1" size="lg">
                Dirty Pix Enjoyers Club
              </Heading>
              <Box p={4} w="30%" minW={100} textAlign="center">
                <Text p={1}>
                  Minted: {minted}/{MAX_SUPPLY}
                </Text>
                <Progress size="xs" max={MAX_SUPPLY} value={minted} />
                {mintedOut && (
                  <Text variant="important" p={1}>
                    SOLD OUT
                  </Text>
                )}
              </Box>

              <Box p={6} mt={0} w="60%" minW={140}>
                <Container className="widget" rounded="lg" mb={6} p={3} centerContent>
                  <Container mb={4} centerContent>
                    <Heading variant={whiteListMintEnabled ? "open" : "closed"} size="md">
                      Whitelist Mint
                    </Heading>
                  </Container>
                  <Tooltip label={whiteListMintEnabled ? null : whiteListMintClosedReason}>
                    <Button
                      onClick={whiteListMint}
                      w="50%"
                      isLoading={isWhiteListMintLoading}
                      isDisabled={!whiteListMintEnabled}
                    >
                      <Text p={1}>
                        <PolygonIcon />
                      </Text>
                      {WHITELIST_MINT_PRICE}
                    </Button>
                  </Tooltip>
                </Container>
                <Container className="widget" rounded="lg" mb={6} p={3} centerContent>
                  <Container mb={4} centerContent>
                    <Heading variant={publicMintEnabled ? "open" : "closed"} size="md">
                      Public Mint
                    </Heading>
                  </Container>
                  <Tooltip label={publicMintEnabled ? null : publicMintClosedReason}>
                    <Button
                      onClick={publicMint}
                      w="50%"
                      isLoading={isPublicMintLoading}
                      isDisabled={!publicMintEnabled}
                    >
                      <Text p={1}>
                        <PolygonIcon />
                      </Text>
                      {PUBLIC_MINT_PRICE}
                    </Button>
                  </Tooltip>
                </Container>
              </Box>
            </Container>
          </Box>
        </Flex>
      </ChakraProvider>
    </Container>
  );
}
