import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";
import { injected } from "../libs/connectors";

import { Button, Container, Flex } from "@chakra-ui/react";

const shortenAddress = (address: string) => {
  const prefix = address.slice(0, 6);
  const suffix = address.slice(-4);
  return `${prefix}...${suffix}`;
};

export function WalletConnector() {
  const { account, active, error, activate } = useWeb3React();

  const connect = async () => {
    try {
      await activate(injected);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized && !active && !error) {
        activate(injected);
      }
    });
  }, [activate, active, error]);

  const account_connected = <span id="wallet-address">{account ? shortenAddress(account) : ""}</span>;
  const account_disconnected = <Button onClick={connect}>Connect</Button>;
  let errorMessage: string | null = null;
  if (error) {
    if (error.message.startsWith("Unsupported chain id:")) {
      errorMessage = "Please switch to Polygon network";
    } else {
      errorMessage = error.message;
    }
  }
  console.log(error);
  return (
    <div>
      <Flex justifyContent="right" rounded="md" h="40px">
        {error && (
          <Container style={{ color: "red" }}>
            <span style={{ fontWeight: "bold" }}>Error! </span>
            <span>{errorMessage}</span>
          </Container>
        )}
        <div>{active ? account_connected : account_disconnected}</div>
      </Flex>
    </div>
  );
}
